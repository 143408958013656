import { application } from "../config/stimulus"
import { identifierForContextKey } from "@hotwired/stimulus-webpack-helpers"

import controllers from "./**/*_controller.js"

Object.entries(controllers).forEach(([filename, controller]) => {
  const identifier = identifierForContextKey(filename)

  if (identifier) {
    application.register(identifier, controller.default)
  }
})
