import { Controller } from "@hotwired/stimulus"
import { useMatchMedia } from "stimulus-use"

export default class extends Controller {
  static targets = [
    "main",
    "mobile",
    "opened",
    "closed"
  ]

  static values = {
    open: false
  }

  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        sm: "(max-width: 767px)",
        md: "(min-width: 768px)"
      }
    })
  }

  toggle() {
    this.openValue = !this.openValue
  }

  isSm() {
    this.openValue = false
  }

  isMd() {
    this.openValue = false
  }

  openValueChanged() {
    if (this.openValue === true) {
      this.mobileTarget.classList.remove("hidden")
      this.openedTarget.classList.remove("hidden")
      this.closedTarget.classList.add("hidden")
    } else {
      this.mobileTarget.classList.add("hidden")
      this.openedTarget.classList.add("hidden")
      this.closedTarget.classList.remove("hidden")
    }
  }
}
