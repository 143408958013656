import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"
import tippy from "tippy.js"

export default class extends Controller {

  connect() {
    new ClipboardJS(".copy-btn")
  }

  copy(event) {
    this.element._tippy.show()

    const previousText = this.element.title
    this.element._tippy.setContent("Copied to clipboard");

    setTimeout(() => this.element._tippy.setContent(previousText), 2000)
  }
}
