import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: "_blank"
  }

  connect() {
    Array.from(this.anchors).forEach(this.proccessAnchor)
    this.element.removeAttribute("data-controller")
  }

  proccessAnchor = (anchor) => {
    anchor.target = this.targetValue

    const url = new URL(anchor.href)

    url.searchParams.append("utm_medium", "blog_post")
    url.searchParams.append("utm_source", "marcoroth.dev")
    url.searchParams.append("utm_content", "post")

    anchor.href = url.toString()
  }

  get anchors() {
    return this.element.querySelectorAll("a")
  }
}
